var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenedor",attrs:{"id":"banks"}},[_c('loader',{attrs:{"show":_vm.show}}),_c('sesion',{attrs:{"expiration":_vm.expiration}}),_c('Sidebar'),_c('v-container',{staticClass:"base text-center",attrs:{"fluid":""}},[_c('v-row',{staticClass:"titulo ml-10 mt-5",attrs:{"no-gutters":""}},[_vm._v("LISTA DE BANCOS")]),_c('v-row',{staticClass:"mr-12",attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"10","xs":"12"}},[_c('v-card-title',[_c('router-link',{attrs:{"to":"/addBank","id":"nuevoBanco"}},[_c('button',{staticClass:"breakSearch botonAmarillo"},[_vm._v("Alta de Banco")])]),_c('v-text-field',{staticClass:"mr-2",attrs:{"append-icon":"search","label":"Buscador","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-row',{staticClass:"datos pa-0",staticStyle:{"height":"auto !important"},attrs:{"no-gutters":""}},[_c('v-card',{staticStyle:{"width":"100%","background":"rgb(199, 195, 199) !important"}},[_c('v-data-table',{staticClass:"elevation-2 text--dark",attrs:{"headers":_vm.headers,"items":_vm.banks,"search":_vm.search,"loading-text":"Cargando datos... Por favor espere","items-per-page":5,"no-results-text":'No se encontraron incidencias',"no-data-text":'No hay datos',"footer-props":{
            'items-per-page-text': 'Bancos por página',
            'items-per-page': [5, 10, 15],
            'items-per-page-all-text': 'Todos',
            'items-per-page-options': [5, 10],
            'loading-text': 'Obteniendo datos... Por favor espere',
            'no-data-text': 'No hay datos...',
            'no-results-text': 'No se encontraron incidencias'
          }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"#ffffff","icon":"warning"}},[_c('p',{staticStyle:{"color":"black"}},[_vm._v("No hay datos en sistema ...")])])]},proxy:true},{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,class:{ selectedRow: item === _vm.selectedItem }},[_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.nombreCorto))]),_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.razonSocial))]),_c('td',{staticClass:"justify-center layout px-0"},[_c('v-icon',{staticClass:"mr-6",attrs:{"color":"#717171","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("fa-edit")]),_c('v-icon',{staticClass:"mr-6",attrs:{"color":"#717171","small":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v("fa-eye")]),_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.temporal(item)}}},[_vm._v("fa-trash-alt")])],1)])}),0)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.advice),callback:function ($$v) {_vm.advice=$$v},expression:"advice"}},[_c('v-card',[_c('v-card-title',{staticClass:"red white--text"},[_c('span',{staticClass:"headline"},[_vm._v("Aviso")])]),_c('v-card-text',[_c('br'),_c('h3',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.respuesta))]),_c('br')]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.advice = false;
            _vm.id = '';}}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Aceptar")])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"450px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"orange white--text"},[_vm._v("Información de Banco")]),_c('v-card-text',{staticStyle:{"height":"200px"},attrs:{"id":"info"}},[_c('div',{staticClass:"form-group mt-5"},[_c('label',[_vm._v("Clave CFDI:")]),_vm._v(" "+_vm._s(_vm.cfdi)+" ")]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Clave SPEI:")]),_vm._v(" "+_vm._s(_vm.spei)+" ")]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nombre Comercial:")]),_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Razón Social:")]),_vm._v(" "+_vm._s(_vm.reason)+" ")]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Inicio de Vigencia:")]),_vm._v(" "+_vm._s(_vm.startDate)+" ")]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fin de Vigencia:")]),_vm._v(" "+_vm._s(_vm.finishDate)+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cerrar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }